import axios from "@/plugins/axios";
// import authHeader from '../auth-header'
import ConfigService from "../config";
import authHeaderMultipart from "../auth-header-multipart"
import authHeader from "../auth-header"
const API_URL = ConfigService.base_url;

class ProductServiceApi {
  getAllProducts(data) {
    return axios
      .post(API_URL + "products/filter",this.clean(data)
      )
      .then((response) => {
        console.log(response.data)
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  productAction(product){
    return axios
    .post(API_URL + 'product', product,
    {
      headers: authHeaderMultipart()
    })
    .then(response => {
      return response;
    })
    .catch(error=> {
        return Promise.reject(error);
      }
    )
  }
  getProductData(id){
    return axios
    .get(API_URL + 'admin/products', 
    {
      params:{ product_id: id},
      headers: authHeader()
    })
    .then(response => {
      return response;
    })
    .catch(error=> {
        return Promise.reject(error);
      }
    )
  }
  clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj
  }
  addSize(data){
    return axios
    .post(API_URL + 'size', data,
    {
      headers: authHeaderMultipart()
    })
    .then(response => {
      return response;
    })
    .catch(error=> {
        return Promise.reject(error);
      }
    )
  }
}

export default new ProductServiceApi();
