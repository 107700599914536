<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Buyers from "@/components/product/buyers";
import Sellers from "@/components/product/sellers";
import Transactions from "@/components/product/transactions";
import ProductServiceApi from "@/service/api/product";
import CategoriesServiceApi from "@/service/api/categories";
import Spinner from "@/components/spinner";
import tags from "@/service/prod-tags";
import BrandServiceApi from "@/service/api/brands";
import "vue-search-select/dist/VueSearchSelect.css";
import { ModelSelect } from "vue-search-select";

export default {
  components: {
    Layout,
    PageHeader,
    Buyers,
    Sellers,
    Transactions,
    Spinner,
    ModelSelect,
  },
  data() {
    return {
      title: "Product Detail",
      productId: this.$route.params.id,
      productData: null,
      items: [
        {
          text: "Ecommerce",
        },
        {
          text: "Product Detail",
          active: true,
        },
      ],
      sizeUpdating: false,
      genderOpt: {
        M: "Men",
        F: "Women",
        C: "Children",
        P: " Preschool",
        I: "Toddler",
        T: "Infant",
      },
      productForm: {
        product_id: null,
        name: null,
        desc: null,
        color: null,
        SKU: null,
        condition: null,
        retail_price: null,
        release_date: null,
        image_path: null,
        gender: null,
        grade: null,
        secondary_id: null,
      },
      myFiles: [],
      mainCatSelect: null,
      primaryCatSelect: null,
      primaryCategory: null,
      secondaryCategory: null,
      categories: null,
      loading: false,
      update_size: false,
      tagSelect: [],
      availableTag: [],
      brandData: null,
      brandSelect: {
        value: null,
        text: null,
      },
    };
  },
  methods: {
    submitProductHandler(data) {
      var form_data = new FormData();
      form_data.append("color", data.color);
      form_data.append("condition", data.condition);
      form_data.append("desc", data.desc);
      form_data.append("gender", data.gender);
      if (
        this.productForm.image_path &&
        this.productForm.image_path.files &&
        this.productForm.image_path.files[0]
      ) {
        form_data.append(
          "image_path",
          this.productForm.image_path.files[0].file
        );
      }
      form_data.append("product_id", data.product_id);
      form_data.append("name", data.name);
      form_data.append("release_date", data.release_date);
      form_data.append("retail_price", data.retail_price);
      form_data.append("secondary_id", data.secondary_id);
      form_data.append("popular", data.popular ? "1" : "0");
      form_data.append("SKU", data.SKU);
      form_data.append("brand_id", this.brandSelect.value);
      form_data.append("action", "edit");

      ProductServiceApi.productAction(form_data).then((response) => {
        if (response.data.success) {
          this.productUpdateMessage(response.data.product[0]);
        } else {
          this.productUpdateErrorMessage(response.data.error);
        }
        this.loadProduct();
      });
    },
    filterPrimaryCategory(id = null) {
      if (id) {
        let primeData = this.categories.filter(
          (item) => item.id === parseInt(id)
        );
        if (primeData.length > 0) {
          return primeData[0].primary;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    resetForm() {
      this.setForm(this.productData);
    },
    productUpdateMessage(data, variant = "success") {
      this.$bvToast.toast(`Product ${data.name} is updated !`, {
        title: `Product update`,
        variant: variant,
        solid: true,
      });
    },
    productUpdateErrorMessage(data, variant = "danger") {
      this.$bvToast.toast(`Product is not updated!, failed with error`, {
        title: `Opration Failed`,
        variant: variant,
        solid: true,
      });
    },
    newSizeAddMessage(variant = "success") {
      this.$bvToast.toast(`Product size list is updated !`, {
        title: `Size update`,
        variant: variant,
        solid: true,
      });
    },
    newSizeAddFailedMessage(variant = "danger") {
      this.$bvToast.toast(`Product size list is not updated !`, {
        title: `Size update failed`,
        variant: variant,
        solid: true,
      });
    },
    setForm(val) {
      this.mainCatSelect = val.category_id;
      this.primaryCatSelect = val.primaryCategory_id;

      this.productForm = {
        product_id: val.id,
        name: val.name,
        desc: val.desc,
        color: val.color,
        SKU: val.SKU,
        condition: val.condition,
        popular: val.popular === "1" ? 1 : 0,
        retail_price: val.retail_price,
        release_date: this.moment(new Date(val.release_date)).format(
          "YYYY-MM-DD"
        ),
        image_path: val.image_path,
        gender: val.gender,
        grade: val.grade,
        secondary_id: val.secondaryCategory_id,
      };
      this.brandSelect = {
        text: val.brand.name_en,
        value: val.brand.id,
      };
    },

    filterSecondaryCategory(id = null) {
      if (id && this.primaryCategory) {
        let secondData = this.primaryCategory.filter(
          (item) => item.id === parseInt(id)
        );

        if (secondData.length > 0) {
          const data = secondData[0].secondary.map((item) => {
            return {
              label: `${item.name}`,
              value: item.id,
            };
          });
          this.secondaryCategory = data;
          return data;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    async loadProduct() {
      this.loading = true;
      await ProductServiceApi.getProductData(this.productId)
        .then((response) => {
          this.productData = response.data.product;
          this.productData.sizes.map((item) => {
            this.tagSelect.push(item.size);
          });
          this.availableTag = this.findAvailableTag();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    findAvailableTag() {
      let availableTags = [];
      tags[this.productData.category.name_en].forEach((item) => {
        if (this.tagSelect.filter((tag) => tag === item).length) {
          availableTags.push({ text: item, value: item, disabled: true });
        } else {
          availableTags.push(item);
        }
      });
      return availableTags;
    },
    async UpdateSize() {
      let takenSize = new Set(this.productSizes);
      let selectedSize = new Set(this.tagSelect);
      let newSize = new Set([...selectedSize].filter((x) => !takenSize.has(x)));

      var form_data = new FormData();
      form_data.append("action", "add");
      form_data.append("product_id", this.productData.id);
      newSize.forEach((item) => {
        form_data.append("sizelist[]", item);
      });
      this.sizeUpdating = true;
      await ProductServiceApi.addSize(form_data)
        .then((response) => {
          if (response.data.success) {
            this.newSizeAddMessage();
            this.loadProduct();
          } else {
            this.newSizeAddFailedMessage();
            this.tagSelect = this.productSizes;
          }
        })
        .finally(() => {
          this.sizeUpdating = false;
        });
    },
    loadAllBrand() {
      BrandServiceApi.getAllBrands().then((response) => {
        this.brandData = response.data;
      });
    },
  },
  mounted() {
    this.loadAllBrand();
    this.loadProduct();

    CategoriesServiceApi.getAllCategories().then((response) => {
      this.categories = response.data;
    });
  },
  watch: {
    productData(val) {
      this.setForm(val);
    },
    mainCatSelect(val) {
      if (val) {
        this.primaryCategory = this.filterPrimaryCategory(val);
      }
    },
    primaryCatSelect(val) {
      this.secondaryCategory = this.filterSecondaryCategory(val);
    },
  },
  computed: {
    brandDataList() {
      return this.brandData
        ? this.brandData.map((item) => {
            return { value: item.id, text: item.name_en };
          })
        : null;
    },
    imageUrl() {
      return this.productData.image;
    },
    productSizes() {
      var size = [];
      this.productData.sizes.map((item) => {
        size.push(item.size);
      });

      return size;
    },
    mainCategory() {
      return this.categories.map((item) => {
        return {
          text: `${item.name_en}-${item.name_ar}`,
          value: item.id,
        };
      });
    },
    primaryCategoryOpt() {
      if (this.primaryCategory) {
        return this.primaryCategory.map((item) => {
          return {
            text: `${item.name}`,
            value: item.id,
          };
        });
      } else {
        return [];
      }
    },
  },
};
</script>

<template>
  <Layout>
    <div
      style="position:absolute;margin-left: calc( 45% - 160px );margin-top:40px"
    >
      <Spinner :show="loading" />
    </div>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" v-if="productData">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-5 col-md-6">
                <div class="product-detail">
                  <div class="row">
                    <div class="col-12">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="product">
                          <div class="product-img">
                            <img
                              id="productImg"
                              :src="productData.image"
                              alt
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-lg-7 col-md-6">
                <div class="mt-4 mt-xl-3">
                  <a
                    class="text-primary"
                    v-if="productData.secondary_category"
                    >{{ productData.secondary_category.name }}</a
                  >
                  <h5 class="mt-1 mb-3">{{ productData.name }}</h5>

                  <h5 class="mt-2">${{ productData.retail_price }}</h5>
                  <div>
                    {{ productData.desc }}
                  </div>
                  <div v-if="productData.brand">
                    <p class="mt-3">
                      <img
                        :src="productData.brand.image"
                        alt=""
                        srcset=""
                        height="50"
                      />
                      <span style="display:block;">
                        {{ productData.brand.name_en }}
                      </span>
                    </p>
                  </div>
                  <p class="mt-3">Color : {{ productData.color }}</p>
                  <div class="row">
                    <div class="col-12">
                      <div class="product-color mt-3">
                        <h5 class="font-size-14"> <span v-if="productData.category_id !=='3'"> Size :</span> <span v-else>Grade :</span> </h5>
                        <a v-for="(size, index) in productSizes" :key="index">
                          <div class="product-color-item p-1">
                            {{ size }}
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->

            <div class="mt-4">
              <h5 class="font-size-14 mb-3">Product description:</h5>
              <div class="product-desc">
                <b-tabs
                  class="nav-tabs-custom"
                  content-class="border border-top-0 p-4"
                >
                  <b-tab title="Specifications">
                    <div class="table-responsive">
                      <table class="table table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row" style="width: 400px;">Category</th>
                            <td></td>
                          </tr>
                          <tr v-if="productData.brand">
                            <th scope="row">Brand</th>
                            <td>
                              {{ productData.brand.name_en }}
                              <span class="text-arabic">
                                {{ productData.brand.name_ar }}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Color</th>
                            <td>{{ productData.color }}</td>
                          </tr>
                          <tr>
                            <th scope="row">SKU</th>
                            <td>{{ productData.SKU }}</td>
                          </tr>
                          <tr>
                            <th scope="row">Condition</th>
                            <td>{{ productData.condition }}</td>
                          </tr>
                          <tr>
                            <th scope="row">Gender</th>
                            <td>{{ productData.genderType }}</td>
                          </tr>
                          <tr>
                            <th scope="row">Release Date</th>
                            <td>{{ productData.release_date }}</td>
                          </tr>
                          <tr v-if="productData.grade">
                            <th scope="row">Grade</th>
                            <td>{{ productData.grade }}</td>
                          </tr>
                          <tr>
                            <th scope="row">Lowest Ask</th>
                            <td>{{ productData.lowest_ask }}</td>
                          </tr>
                          <tr>
                            <th scope="row">Highest Bid</th>
                            <td>{{ productData.highest_bid }}</td>
                          </tr>
                          <tr>
                            <th scope="row" v-if="productData.category">
                              Main Category
                            </th>
                            <td>
                              {{ productData.category.name_en }}
                              <span class="text-arabic">
                                {{ productData.category.name_ar }}
                              </span>
                            </td>
                          </tr>
                          <tr v-if="productData.primary_category">
                            <th scope="row">Primary Category</th>
                            <td>{{ productData.primary_category.name }}</td>
                          </tr>
                          <tr v-if="productData.secondary_category">
                            <th scope="row">Secondary Category</th>
                            <td>{{ productData.secondary_category.name }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-tab>

                  <b-tab title="Bids">
                    <Buyers :productId="productId" :sizes="productData.sizes" />
                  </b-tab>
                  <b-tab title="Asks">
                    <Sellers
                      :productId="productId"
                      :sizes="productData.sizes"
                    />
                  </b-tab>
                  <b-tab title="Transaction">
                    <Transactions
                      :productId="productId"
                      :sizes="productData.sizes"
                    />
                  </b-tab>
                  <b-tab title="Edit" id="productEditFormId">
                    <div>
                      <FormulateForm
                        name="productAddForm"
                        @submit="submitProductHandler"
                        v-model="productForm"
                      >
                        <div style="margin: 10px 30px">
                          <div class="row">
                            <div class="col-md-6">
                              <FormulateInput
                                type="text"
                                name="name"
                                label="Name"
                                placeholder="Product name here"
                                validation="required"
                              />
                              <FormulateInput
                                type="image"
                                name="image_path"
                                label="Select an image to upload"
                                help="Select a png, jpg or gif to upload."
                                validation="mime:image/jpeg,image/png,image/gif|required"
                              />
                            </div>
                            <div class="col-md-6">
                              <div class="product-preview-form">
                                <div class="product-box" style="height:262px;">
                                  <div class="product-img mb-1">
                                    <img
                                      :src="imageUrl"
                                      v-if="imageUrl"
                                      onerror="javascript:this.src=''"
                                      alt="Please upload image"
                                      class="img-fluid mx-auto d-block"
                                      style="height:148px;width:158px;"
                                    />
                                    <img
                                      v-else
                                      src="@/assets/images/noimage.png"
                                      alt="Please upload image"
                                      class="img-fluid mx-auto d-block"
                                      style="height:148px;width:158px;"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <FormulateInput
                                type="textarea"
                                name="desc"
                                label="Description"
                                placeholder="Description here"
                                validation="required"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <FormulateInput
                                type="number"
                                name="retail_price"
                                label="Retail Price"
                                validation="required"
                                placeholder="Retail price here"
                                min="0"
                              />
                            </div>
                            <div class="col-md-6">
                              <FormulateInput
                                type="text"
                                name="SKU"
                                label="SKU"
                                validation="required"
                                placeholder="Product SKU here"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <FormulateInput
                                type="date"
                                name="release_date"
                                label=" Release Date"
                                validation="required"
                                v-model="productForm.release_date"
                                placeholder="Release date"
                              />
                            </div>
                            <div class="col-md-6">
                              <FormulateInput
                                name="gender"
                                :options="genderOpt"
                                type="select"
                                validation="required"
                                placeholder="Gender"
                                label="Gender"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                               <FormulateInput
                                type="text"
                                name="color"
                                label="Color"
                                validation="required"
                                placeholder="Product color"
                              />
                            </div>
                            <div class="col-md-6">
                                <FormulateInput
                                :options="{'New': 'New','Graded': 'Graded'}"
                                type="radio"
                                name="condition"
                                label="Condition"
                                validation="required"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6 pt-2 pb-4">
                              <FormulateInput
                                type="checkbox"
                                name="popular"
                                label="Is Popular"
                                placeholder="Is Pouplar?"
                              />
                            </div>
                          </div>
                          <div class="row" style="margin-top:10px;">
                            <div class="col-md-6">
                              <label for="textarea-small">Category</label>
                              <b-form-select
                                v-model="mainCatSelect"
                                :options="mainCategory"
                              ></b-form-select>
                            </div>

                            <div class="col-md-6 custom-search">
                              <div
                                style="font-weight:600;margin-bottom:6px;margin-top:2px;"
                              >
                                Brands
                              </div>
                              <model-select
                                :options="brandDataList"
                                v-model="brandSelect"
                                placeholder="Select a Brand"
                              >
                              </model-select>
                              <div
                                style="font-size:12px;color:#960505;font-weight:100"
                                v-if="!brandSelect.value"
                              >
                                Brand is required!
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6 pt-2">
                              <label for="textarea-small"
                                >Primary category</label
                              >
                              <b-form-select
                                v-model="primaryCatSelect"
                                :options="primaryCategoryOpt"
                              ></b-form-select>
                            </div>
                            <div class="col-md-6">
                              <FormulateInput
                                name="secondary_id"
                                :options="secondaryCategory"
                                type="select"
                                placeholder="Select secondary category"
                                label="Secondary category"
                                validation="required"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="m-4" style="padding-bottom:50px;">
                          <div class="mt-4 text-center m-2">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light float-left"
                              type="submit"
                            >
                              Update
                            </button>

                            <button
                              class="btn btn-primary w-md waves-effect waves-light float-right"
                              type="button"
                              @click="resetForm"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </FormulateForm>
                    </div>
                  </b-tab>
                  <b-tab :title="()=>{
                    if(productData.category_id !== '3') 
                      return 'Size' 
                      else return 'Grade'}" v-if="tagSelect[0] !== 'ONE SIZE'">
                    <div>
                      <div class="row">
                        <div class="col-12 product-tag-wr-st">
                          <div class="product-tag-st">
                            Please select available sizes
                            <b-form-checkbox-group
                              v-model="tagSelect"
                              :options="availableTag"
                            ></b-form-checkbox-group>
                          </div>
                          <button
                            class="btn btn-primary"
                            v-on:click="UpdateSize"
                          >
                            <span v-if="sizeUpdating">Updating Size...</span>
                            <span v-else>Update Size</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<style>
.text-arabic {
  display: block;
}
.product-tag-st {
  margin: 30px 0px;
}

.product-tag-st .custom-control-inline {
  width: 60px;
}
.custom-select {
  background-color: #f1f1f1;
  border: none;
}
.custom-search .dropdown {
  background-color: #f1f1f1 !important;
  border: none !important;
}
#productEditFormId .formulate-input-group{
  display: flex;
    justify-content: space-between;
    width: 174px;
}
</style>
