<script>
import ProductDetailsServiceApi from "@/service/api/productDetails";
import Spinner from "@/components/spinner";
export default {
  components: { Spinner },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      rows: null,
      buyersTableData: [],
      loading: false,
      buyersTotalRows: 1,
      buyersCurrentPage: 1,
      buyersPerPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      buyersSizeSelect: null,
      buyersFields: [
        { key: "id", sortable: false },
        { key: "user", sortable: false },
        { key: "available", sortable: false },
        { key: "expiry", sortable: false },

        { key: "bid_price", sortable: false },
        { key: "amount_due", sortable: false ,label:"Payout Amount"},

        { key: "created_at", sortable: false },
        { key: "updated_at", sortable: false },
        { key: "address", sortable: false },
        { key: "status", sortable: false },
      ],
    };
  },
  props: ["productId", "sizes"],
  computed: {
    sizeOption() {
      if (this.sizes) {
        return this.sizes.map((item) => {
          return {
            text: `${item.size}`,
            value: item.id,
          };
        });
      } else {
        return [];
      }
    },
    totalPages() {
      return parseInt(this.rows / this.perPage);
    },
  },
  watch: {
    buyersSizeSelect(val) {
      this.getAllBuyers(val);
    },
    currentPage(val) {
      this.getAllBuyers(this.buyersSizeSelect, val);
    },
    perPage() {
      this.getAllBuyers(this.buyersSizeSelect);
    },
  },
  mounted() {
    const size = this.sizes ? this.sizes[0].id : null;
    if (size) {
      this.buyersSizeSelect = size;
    }
  },
  methods: {
    //Get All Buyers
    getAllBuyers(size = null, page = 1) {
      this.loading = true;
      if (size) {
        ProductDetailsServiceApi.getTransactionDetailsOfProduct(
          "buyers",
          size,
          page,
          this.perPage
        )
          .then((response) => {
            if (response.data.success) {
              this.buyersTableData = response.data.buyers.data;
              this.rows = response.data.buyers.total;
            }
          })
          .finally(() => (this.loading = false));
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="row mt-4" style="padding-top:10px;">
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Show&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select
            >&nbsp;entries
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            <span style="padding-right:5px;">
              Size
            </span>
            <b-form-select
              size="sm"
              v-model="buyersSizeSelect"
              :options="sizeOption"
            ></b-form-select>
          </label>
        </div>
      </div>
      <!-- End search -->
    </div>

    <div v-if="loading">
      <Spinner :show="loading" />
    </div>
    <div v-else class="table-responsive mb-0">
      <b-table
        :items="buyersTableData"
        :fields="buyersFields"
        responsive="sm"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template v-slot:cell(address)="row">
          <div v-if="row.item.address">
            {{ row.item.address.address }}, {{ row.item.address.city }},
            {{ row.item.address.country }}
          </div>
        </template>
        <template v-slot:cell(user)="row">
          <div v-if="row.item.user">
            {{ row.item.user.name }}
          </div>
        </template>
        <template v-slot:cell(expiry)="row">
          <div v-if="row.value">
            {{ moment(row.value).format("MMMM Do YYYY, h:mm:ss a") }}
          </div>
        </template>
        <template v-slot:cell(created_at)="row">
          <div v-if="row.value">
            {{ moment(row.value).format("MMMM Do YYYY, h:mm:ss a") }}
          </div>
        </template>
        <template v-slot:cell(updated_at)="row">
          <div v-if="row.value">
            {{ moment(row.value).format("MMMM Do YYYY, h:mm:ss a") }}
          </div>
        </template>
        <template v-slot:cell(status)="row">
          <div v-if="row.value">
            <b-badge
              variant="success"
              class="ml-1"
              v-if="row.value === 'active'"
              >{{ row.value }}</b-badge
            >
            <b-badge variant="danger" class="ml-1" v-else>{{
              row.value
            }}</b-badge>
          </div>
        </template>
      </b-table>
      <div v-if="buyersTableData.length < 1" style="text-align:center">
        No Buyers!
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div>
          <p class="mb-sm-0 mt-2">
            Page
            <span class="font-weight-bold">{{ currentPage }}</span> Of
            <span class="font-weight-bold">{{ totalPages + 1 }}</span>
          </p>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              :total-rows="rows"
              :per-page="perPage"
              v-model="currentPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
