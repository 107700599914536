import axios from "@/plugins/axios";
// import authHeader from '../auth-header'
import ConfigService from "../config";
import authHeader from "../auth-header"
const API_URL = ConfigService.base_url;

class ProductDetailsServiceApi {
  getTransactionDetailsOfProduct(type="buyers",size_id,page,paginate=10) {
    return axios
      .get(API_URL + "admin/sizes", {
        params:{ 
            size_id:size_id,
            page: page,
            paginate:paginate,
            type:type
        },
        headers: authHeader()
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  

}

export default new ProductDetailsServiceApi();
