<script>
import ProductDetailsServiceApi from "@/service/api/productDetails";
import UserServiceApi from "@/service/api/users";
import Spinner from "@/components/spinner";
import Progress from "@/service/transactionType";
import TransactionServiceApi from "@/service/api/transaction";

export default {
  components: { Spinner },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      rows: null,
      transactionsTableData: [],
      loading: false,
      transactionsTotalRows: 1,
      transactionsCurrentPage: 1,
      transactionsPerPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      modalData: null,
      transactionsSizeSelect: null,
      addressSeller: null,
      addressBuyer: null,
      loadingSellerAddress: false,
      loadingBuyerAddress: false,
      progress: Progress,
      progressSelected: null,
      transactionsFields: [
        { key: "id", sortable: false },
        { key: "user", sortable: false },
        { key: "type", sortable: false },

        { key: "amount", sortable: false },
        { key: "amount_due", sortable: false },

        { key: "created_at", sortable: false },
        { key: "updated_at", sortable: false },
        { key: "progress", sortable: false },
        { key: "status", sortable: false },
      ],
    };
  },
  props: ["productId", "sizes"],
  computed: {
    sizeOption() {
      if (this.sizes) {
        return this.sizes.map((item) => {
          return {
            text: `${item.size}`,
            value: item.id,
          };
        });
      } else {
        return [];
      }
    },
    totalPages() {
      return parseInt(this.rows / this.perPage);
    },
    progressAvailable() {
      if (this.modalData && this.modalData[0]) {
        var labelData = [];
        for (const [key, value] of Object.entries(
          this.progress[this.modalData[0].type]
        )) {
          labelData.push({
            text: value.label,
            value: key,
          });
        }
        return labelData;
      } else {
        return [];
      }
    },
  },
  watch: {
    transactionsSizeSelect(val) {
      this.getAllTransactions(val);
    },
    currentPage(val) {
      this.getAllTransactions(this.transactionsSizeSelect, val);
    },
    perPage() {
      this.getAllTransactions(this.transactionsSizeSelect);
    },

  },
  mounted() {
    const size = this.sizes ? this.sizes[0].id : null;
    if (size) {
      this.transactionsSizeSelect = size;
    }
  },
  methods: {
    //Get All Transactions
    getProgress(type, id) {
      const label = this.progress[type][id].label;
      const status = this.progress[type][id].status;
      const color = this.progress[type][id].color;
      const progress = `${label}<br/>
      <div style="background-color:${color};font-size:12px;color:white;display:inline-block;padding:2px 5px;border-radius:5px;">${status}</div>`;
      return progress;
    },
    getProgressDetails(type, id) {
      return this.progress[type][id].label;
    },
    getAllTransactions(size = null, page = 1) {
      this.loading = true;
      if (size) {
        ProductDetailsServiceApi.getTransactionDetailsOfProduct(
          "transactions",
          size,
          page,
          this.perPage
        )
          .then((response) => {
            if (response.data.success) {
              this.transactionsTableData = response.data.transactions.data;
              this.rows = response.data.transactions.total;
              console.log(response.data.transactions);
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    transactionSelected(val) {
      const selctedItem = val.pop().id;

      this.viewTransaction(selctedItem);
    },
    viewTransaction(id) {
      this.modalData = this.transactionsTableData.filter(
        (result) => result.id === id
      );
      this.progressSelected = this.modalData[0].progress;
      this.addressSeller = null;
      this.addressBuyer = null;
      this.$refs["view-transaction"].show();
    },
    buyerAddress(id) {
      this.loadingBuyerAddress = true;
      this.addressBuyer = this.loadAddress(id, "buyer").then(() => {
        this.loadingBuyerAddress = false;
      });
    },
    sellerAdress(id) {
      this.loadingSellerAddress = true;
      this.addressSeller = this.loadAddress(id, "seller").then(() => {
        this.loadingSellerAddress = false;
      });
    },
    async loadAddress(id, type) {
      await UserServiceApi.getUserAddress(id).then((response) => {
        if (response.data) {
          if (type === "seller") {
            if (response.data.success) {
              this.addressSeller = response.data.address;
            } else {
              this.addressSeller = null;
            }
          } else {
            if (response.data.success) {
              this.addressBuyer = response.data.address;
            } else {
              this.addressBuyer = null;
            }
          }
        }
      });
    },
    updateProgress() {
      if (this.progressSelected && this.modalData[0].id) {
        if (this.progressSelected === "4") {
          this.cancelTransaction(this.progressSelected, this.modalData[0].id);
        } else {
          this.updateTransaction(this.progressSelected, this.modalData[0].id);
        }
      }
    },
    updateTransaction(selected, transaction) {
      const data = {
        transaction_id: transaction,
        progress: selected,
        action: "update",
      };
      TransactionServiceApi.updateTransaction(data)
        .then((response) => {
          if (response.data.success) {
            this.progressUpdateMessage(response.data.transaction[0].progress);
            this.modalData[0].progress = response.data.transaction[0].progress;
          } else {
            this.progressUpdateFailedMessage(response.data.error);
          }
        })
        .catch(() => {
          this.progressUpdateFailedMessage();
        });
    },
    cancelTransaction(selected, transaction) {
      const data = {
        transaction_id: transaction,
        progress: selected,
      };
      TransactionServiceApi.cancelTransaction(data)
        .then((response) => {
          if (response.data.success) {
            this.progressUpdateMessage(response.data.transaction[0].progress);
            this.modalData[0].progress = response.data.transaction[0].progress;
          } else {
            this.progressUpdateFailedMessage(response.data.error);
          }
        })
        .catch(() => {
          this.progressUpdateFailedMessage();
        });
    },
    progressUpdateMessage(data = null, variant = "success") {
      const dataShow = this.getProgressDetails(this.modalData[0].type, data);
      this.$bvToast.toast(`Transaction updated to ${dataShow}!`, {
        title: `Transaction progress status`,
        variant: variant,
        solid: true,
      });
    },
    progressUpdateFailedMessage(data = null, variant = "danger") {
      this.$bvToast.toast(`Transaction failed with message : ${data}`, {
        title: `Transaction progress status`,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>

<template>
  <div>
    <div class="row mt-4" style="padding-top:10px;">
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Show&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select
            >&nbsp;entries
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            <span style="padding-right:5px;">
              Size
            </span>
            <b-form-select
              size="sm"
              v-model="transactionsSizeSelect"
              :options="sizeOption"
            ></b-form-select>
          </label>
        </div>
      </div>
      <!-- End search -->
    </div>

    <div v-if="loading">
      <Spinner :show="loading" />
    </div>
    <div v-else class="table-responsive mb-0">
      <b-table
        :items="transactionsTableData"
        :fields="transactionsFields"
        responsive="sm"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        @row-selected="transactionSelected"
        :filter-included-fields="filterOn"
        selectable
        select-mode="single"
      >
        <template v-slot:cell(user)="row">
          <div v-if="row.item.seller">
            {{ row.item.seller.user.name }}
          </div>
          <div v-else>
            {{ row.item.buyer.user.name }}
          </div>
        </template>
        <template v-slot:cell(progress)="row">
          <div
            v-if="row.value"
            v-html="getProgress(row.item.type, row.value)"
          ></div>
        </template>

        <template v-slot:cell(created_at)="row">
          <div v-if="row.value">
            {{ moment(row.value).format("MMMM Do YYYY, h:mm:ss a") }}
          </div>
        </template>
        <template v-slot:cell(updated_at)="row">
          <div v-if="row.value">
            {{ moment(row.value).format("MMMM Do YYYY, h:mm:ss a") }}
          </div>
        </template>
        <template v-slot:cell(status)="row">
          <div v-if="row.value">
            <b-badge
              variant="success"
              class="ml-1"
              v-if="row.value === 'active'"
              >{{ row.value }}</b-badge
            >
            <b-badge variant="danger" class="ml-1" v-else>{{
              row.value
            }}</b-badge>
          </div>
        </template>
      </b-table>
      <div v-if="transactionsTableData.length < 1" style="text-align:center">
        No Transactions!
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div>
          <p class="mb-sm-0 mt-2">
            Page
            <span class="font-weight-bold">{{ currentPage }}</span> Of
            <span class="font-weight-bold">{{ totalPages + 1 }}</span>
          </p>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>

    <b-modal ref="view-transaction" id="view-transaction" centered size="lg">
      <template #modal-header>
        <div
          v-if="modalData && modalData.length"
          style="margin:auto;font-size:16px;"
        >
          <i style="margin:10px;font-size:16px;" class="ri-line-chart-fill"></i>
          <span>
            {{
              modalData[0].type[0].toUpperCase() + modalData[0].type.slice(1)
            }}
            details
          </span>
        </div>
      </template>
      <div v-if="modalData && modalData.length">
        <div class="table-responsive">
          <table class="table table-nowrap mb-0 no-border-top">
            <tbody>
              <tr>
                <th scope="row" style="width: 400px;">Status</th>
                <td>
                  <b-badge
                    variant="success"
                    class="ml-1"
                    v-if="modalData[0].status === 'active'"
                    >{{ modalData[0].status }}</b-badge
                  >
                  <b-badge variant="danger" class="ml-1" v-else>{{
                    modalData[0].status
                  }}</b-badge>
                </td>
              </tr>
              <tr>
                <th scope="row" style="width: 400px;">Updated at</th>
                <td>
                  {{
                    moment(modalData[0].updated_at).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )
                  }}
                </td>
              </tr>
              <tr>
                <th scope="row" style="width: 400px;">Created at</th>
                <td>
                  {{
                    moment(modalData[0].created_at).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )
                  }}
                </td>
              </tr>
              <tr>
                <th scope="row" style="width: 400px;">Progress</th>
                <td>
                  <div
                    v-html="
                      getProgress(modalData[0].type, modalData[0].progress)
                    "
                  ></div>
                </td>
              </tr>
              <tr>
                <th scope="row" style="width: 400px;">Amount due</th>
                <td>
                  {{ modalData[0].amount_due }}
                </td>
              </tr>
              <tr>
                <th scope="row" style="width: 400px;">Amount</th>
                <td>
                  {{ modalData[0].amount }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="modalData[0].type === 'buy'">
          <div class="row" style="overflow:hidden">
            <div class="col-lg-5">
              <div class="card address-card" style="border-radius:10px;">
                <div class="card-body">
                  <div class="mb-2 h5">
                    Seller
                  </div>
                  <div class="row">
                    <div class="col-3">
                      Name
                    </div>
                    <div class="col-9">
                      {{ modalData[0].seller.user.name }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      Email
                    </div>
                    <div class="col-9">
                      {{ modalData[0].seller.user.email }}
                    </div>
                  </div>
                  <div class="m-2">
                    <a
                      class="primary"
                      @click="sellerAdress(modalData[0].seller.address_id)"
                      >Address</a
                    >
                    <b-spinner
                      v-if="this.loadingSellerAddress"
                      small
                      variant="dark"
                      role="status"
                    ></b-spinner>
                    <div v-if="addressSeller">
                      <div class="row">
                        <div class="col-12">
                          House No : {{ addressSeller.house_number }}
                          {{ addressSeller.address }}<br />
                          {{ addressSeller.city }}<br />
                          {{ addressSeller.country }} <br />
                          {{ addressSeller.phone_number }}<br />
                          Country Code : {{ addressSeller.country_code }} <br />
                          Shiping Fee : {{ addressSeller.shipping_fee }} <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-2"
              style="display: flex;justify-content: center;align-items: center"
            >
              <div style="font-size:45px;">
                <i class="ri-arrow-right-circle-fill"> </i>
              </div>
            </div>
            <div class="col-lg-5">
              <div class="card address-card" style="border-radius:10px;">
                <div class="card-body">
                  <div class="mb-2 h5">
                    Buyer
                  </div>
                  <div class="row">
                    <div class="col-4">
                      Name
                    </div>
                    <div class="col-8">
                      {{ modalData[0].user.name }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      Email
                    </div>
                    <div class="col-8">
                      {{ modalData[0].user.email }}
                    </div>
                  </div>
                  <div class="m-2">
                    <a
                      class="primary"
                      @click="buyerAddress(modalData[0].address_id)"
                    >
                      Address</a
                    >
                    <b-spinner
                      v-if="this.loadingBuyerAddress"
                      small
                      variant="dark"
                      role="status"
                    ></b-spinner>

                    <div v-if="addressBuyer">
                      <div class="row">
                        <div class="col-12">
                          House No : {{ addressBuyer.house_number }}
                          {{ addressBuyer.address }}<br />
                          {{ addressBuyer.city }}<br />
                          {{ addressBuyer.country }} <br />
                          {{ addressBuyer.phone_number }}<br />
                          Country Code : {{ addressBuyer.country_code }} <br />
                          Shiping Fee : {{ addressBuyer.shipping_fee }} <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row" style="overflow:hidden">
            <div class="col-lg-5">
              <div class="card address-card" style="border-radius:10px;">
                <div class="card-body">
                  <div class="mb-2 h5">
                    Seller
                  </div>
                  <div class="row">
                    <div class="col-4">
                      Name
                    </div>
                    <div class="col-8">
                      {{ modalData[0].user.name }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      Email
                    </div>
                    <div class="col-8">
                      {{ modalData[0].user.email }}
                    </div>
                  </div>
                  <div class="m-2">
                    <a
                      class="primary"
                      @click="buyerAddress(modalData[0].address_id)"
                    >
                      Address</a
                    >
                    <b-spinner
                      v-if="this.loadingBuyerAddress"
                      small
                      size="1px"
                      variant="dark"
                      role="status"
                    ></b-spinner>

                    <div v-if="addressBuyer">
                      <div class="row">
                        <div class="col-12">
                          House No : {{ addressBuyer.house_number }}
                          {{ addressBuyer.address }}<br />
                          {{ addressBuyer.city }}<br />
                          {{ addressBuyer.country }} <br />
                          {{ addressBuyer.phone_number }}<br />
                          Country Code : {{ addressBuyer.country_code }} <br />
                          Shiping Fee : {{ addressBuyer.shipping_fee }} <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-2"
              style="display: flex;justify-content: center;align-items: center"
            >
              <div style="font-size:45px;">
                <i class="ri-arrow-right-circle-fill"> </i>
              </div>
            </div>
            <div class="col-lg-5">
              <div class="card address-card" style="border-radius:10px;">
                <div class="card-body">
                  <div class="mb-2 h5">
                    Buyer
                  </div>
                  <div class="row">
                    <div class="col-4">
                      Name
                    </div>
                    <div class="col-8">
                      {{ modalData[0].buyer.user.name }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      Email
                    </div>
                    <div class="col-8">
                      {{ modalData[0].buyer.user.email }}
                    </div>
                  </div>
                  <div class="m-2">
                    <a
                      class="primary"
                      @click="sellerAdress(modalData[0].buyer.address_id)"
                      >Address</a
                    >
                    <b-spinner
                      v-if="this.loadingSellerAddress"
                      small
                      variant="dark"
                      role="status"
                    ></b-spinner>
                    <div v-if="addressSeller">
                      <div class="row">
                        <div class="col-12">
                          House No : {{ addressSeller.house_number }}
                          {{ addressSeller.address }}<br />
                          {{ addressSeller.city }}<br />
                          {{ addressSeller.country }} <br />
                          {{ addressSeller.phone_number }}<br />
                          Country Code : {{ addressSeller.country_code }} <br />
                          Shiping Fee : {{ addressSeller.shipping_fee }} <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<style>
.card-body a:hover {
  cursor: pointer;
}
.no-border-top th {
  border-top: 1px solid #ffffff;
}

.no-border-top td {
  border-top: 1px solid #ffffff;
}
.address-card {
  background-color: rgb(255, 253, 240);
}
</style>
